.time {
  text-align: left;
  margin-left: 24px;
  padding-top: 24px;
}

.home-page {
  margin: 0 auto;
  padding-top: 20px;
  font-family: Arial, sans-serif;
}

.header-title {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.score-container {
  margin-left: auto;
  margin-right: auto;
}

.runs-container {
  display: flex;
  justify-content: space-between;
}

.best-runs,
.worst-runs {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 5px;
}

.nav-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nav-links a {
  text-decoration: none;
  margin: 0 10px;
  color: #333;
  font-weight: bold;
}

.nav-links a:hover {
  color: #3498db;
}
