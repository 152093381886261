@media only screen and (max-width: 600px) {
  #CompareFilters {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }
  #compareUrlsBox {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    max-width: 400px;
  }
  #compareDateBox {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: baseline;
    justify-content: space-between;

    #paddingBox {
      visibility: hidden;
      display: none;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 720px) {
  #compareUrlsBox {
    display: flex !important;
    flex-direction: column !important;
    gap: 16px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  #CompareFilters {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-right: 20px;
    padding-left: 20px;
  }
  #compareUrlsBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px 20px;
    > div {
      display: flex;
      flex-grow: 1;
    }
  }
  #compareDateBox {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    align-items: baseline;
    justify-content: space-between;

    #paddingBox {
      visibility: hidden;
      display: none;
    }
  }
}
@media only screen and (min-width: 1025px) {
  #CompareFilters {
    padding-right: 20px;
    padding-left: 20px;
    gap: 20px;
  }
  #compareUrlsBox {
    display: flex;
    flex-direction: row;
    gap: 20px 20px;
    > div {
      min-width: 300px;
    }
  }
  #compareDateBox {
    display: flex;
    flex-direction: row;
  }
}