.CircularProgressbar-trail {
  stroke: gray;
}

.graph-container {
  display: flex;
  padding: auto;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.graph-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph {
  display: flex;
  max-width: 80%;
  justify-content: center;
}

/* override circular-progressbar lib css */
.circular-progressbar {
  min-width: 100px;
  width: inherit;
}

svg.CircularProgressbar {
  min-width: 100px;
}
