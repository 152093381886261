a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  color: black;
}

.current a {
  border-bottom: 2px black solid;
  border-radius: 0px;
}

#logoStyle {
  width: 100%;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
