@media only screen and (max-width: 600px) {
  #Filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #URLInput {
    margin: 0px;
    width: 100%;
  }
  #dateInputs {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: flex-end;
    gap: 16px;
    padding-right: 0px !important;
    width: auto;
  }
  #paddingBox {
    visibility: hidden;
    display: none;
  }
}
@media only screen and (min-width: 601px) {
  #dateInputs {
    flex-direction: row;
  }
  #paddingBox {
    display: flex;
  }
}