#TableHeading{
    background-color: lightgrey;
    border-bottom: solid 2px black;
}

.Section-Title{
    font-family:'Times New Roman', Times, serif ;
    /* border: solid 1px black; */
}

.Table-Container{
    display: flex;
    /* padding-bottom: 10px; */
    margin: auto;
    max-width: 90%;
    border: solid 1px black;
}

.Table-Body{
    border-left: solid 1px black;
    border-right: solid 1px black;
}