#DivForData {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: auto;
  align-content: center;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      width: 50%;
    }
  }
  @media only screen and (min-width: 601px) {
    flex-direction: row;
  }
}
